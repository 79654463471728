<template>
  <div class="tw-space-y-4 engagement-info">
    <div
      class="tw-flex tw-flex-col tw-justify-center tw-p-4 tw-space-y-2 tw-bg-white tw-rounded-3xl md:tw-px-14 md:tw-py-4"
      style="box-shadow: 11px 8px 56px rgba(66, 46, 135, 0.09)">
      <h1 class="tw-text-[#422e87] tw-text-[25px] tw-font-semibold leading-[48px]">
        {{ $t('requestInterview.EngagementInfo') }}
      </h1>
      <hr class="tw-my-5" />
      <div class="d-flex md:tw-flex-row tw-flex-col tw-gap-4">
        <div class="tw-w-full">
          <label class="form-label required">{{
            $t("requestInterview.engagementInfo")
          }}</label>
          <v-select v-model="engagementInfo.availability" @blur="$v.engagementInfo.availability.$touch()" @input="(id) =>handleChangeAvailability(id , true)"
            :reduce="(name) => name.id" class="style-chooser" :placeholder="$t('requestInterview.EngagementInfo')"
            :options="availabilitiesList" label="name" :selectable="option => !option.disabled">
            <div slot="no-options">
              {{ $t("talent.sorryNoMatchingOptions") }}
            </div>
          </v-select>
          <div class="error-select-validation-message">
            <VulidateError errMsgStyle="tw-text-red-500 tw-text-sm" :validationField="$v.engagementInfo.availability"
              :params="['required']" />
          </div>
        </div>
        <div class="tw-w-full">
          <label class="form-label required">{{
            $t("requestInterview.chooseDuration")
          }}</label>
          <v-select v-model="engagementInfo.duration" @blur="$v.engagementInfo.duration.$touch()"
            :reduce="(name) => name.id" class="style-chooser" :placeholder="$t('requestInterview.chooseDuration')"
            :options="durationsList" label="name">
            <div slot="no-options">
              {{ $t("talent.sorryNoMatchingOptions") }}
            </div>
          </v-select>
          <div class="error-select-validation-message">
            <VulidateError errMsgStyle="tw-text-red-500 tw-text-sm" :validationField="$v.engagementInfo.duration"
              :params="['required']" />
          </div>
        </div>
      </div>
    </div>

    <div
      class="tw-flex tw-flex-col tw-justify-center tw-p-4 tw-space-y-2 tw-bg-white tw-rounded-3xl md:tw-px-14 md:tw-py-4"
      style="box-shadow: 11px 8px 56px rgba(66, 46, 135, 0.09)">
      <div class="row">
        <div class="tw-text-start col-12">
          <div class="p-0 tw-mb-4 tw-flex tw-flex-col md:tw-flex-row tw-justify-between tw-items-center">
            <div class="tw-w-full md:tw-w-2/3">
              <h5 class="tw-mb-0 tw-text-[#202020] tw-text-base tw-font-semibold tw-leading-loose">
                {{ $t("requestInterview.mainHeading") }}
              </h5>
              <p class="tw-text-[#422e87] tw-mb-2 tw-text-xs tw-font-normal tw-leading-relaxed">
                {{ $t("requestInterview.secondaryHeading") }}
              </p>
            </div>

            <div class="tw-w-full md:tw-w-[30%] tw-relative">
              <v-select placeholder="Select a timezone" v-model="selectedTimezone" :options="timezones"
                :clearable="false" class="style-chooser tw-w-full" label="name" :reduce="(name) => name.value">
              </v-select>
            </div>
          </div>

          <div class="">
            <div class="ltr">
              <Calendar ref="datePickerRef" :disabled-dates="disabledDates" v-model="selectedDate"
                :min-date="new Date(Date.now() + 2 * 24 * 60 * 60 * 1000)" :attributes="attributes" :trim-weeks="false"
                is-expanded @dayclick="onDayClick" />
            </div>
            <div class="tw-mt-5 tw-flex tw-flex-col md:tw-flex-row tw-justify-between tw-gap-4">
              <div class="tw-flex tw-flex-col tw-justify-between tw-w-full tw-mb-5" v-for="(day, i) in days" :key="i">
                <div class="tw-flex tw-flex-row tw-items-center tw-justify-between tw-w-full">
                  <div class="tw-flex tw-flex-row">
                    <span
                      class="tw-text-[#484d54] text-sm font-normal md:tw-text-base tw-leading-relaxed tw-me-1 md:tw-me-2">
                      {{ $t("requestInterview.selectTimeFor") }}
                    </span>
                    <span
                      class="tw-text-[#484d54] text-sm font-normal md:tw-text-base md:tw-font-semibold tw-leading-relaxed">
                      {{ dataFormat(day.date) }}</span>
                  </div>

                  <div
                    class="time-slot tw-flex tw-flex-row tw-border-[#C2C2C2] tw-border-solid md:tw-rounded-lg tw-rounded-xl  md:tw-py-2 tw-py-1 md:tw-px-3 tw-px-2 tw-border">
                    <img src="@/assets/images/icons/time.svg" alt="time" />
                    <DatePicker mode="time" v-model="day.time" :valid-hours="getValidHours[i]" class="ltr"
                      :minute-increment="30" is24hr />
                  </div>
                </div>
                <VulidateError class="tw-flex" :validationField="$v.days.$each[i].time" :params="['required']" />
              </div>
            </div>
          </div>
          <div v-if="error" class="tw-text-red-500 tw-text-sm tw-mt-2">
            {{
              days.length == 2
                ? $t("requestInterview.onlySelectTwoTimeSlots")
                : $t("requestInterview.selectTwoTimeSlots")
            }}
          </div>
        </div>
      </div>
    
      <div class="tw-flex tw-items-end tw-justify-between tw-w-full tw-gap-4 tw-my-auto">
            <button
              v-if="interview.status.id === 'client_pending_update' || interview.status.id === 'client_pending' || interview.status.id === 'talent_pending' || interview.status.id === 'talent_pending_update'"
              @click="$emit('cancel')"
              class="tw-px-4 tw-py-3 md:tw-py-3.5 tw-w-full md:tw-w-auto  md:tw-min-w-[10rem]  tw-rounded-lg  tw-justify-center tw-items-center tw-underline tw-gap-2.5 tw-flex tw-text-center tw-text-[#ff6f59] tw-text-[12px]  md:tw-text-base tw-font-medium">
              {{ $t('requestInterview.cancelProcess')}}
            </button>
            <button
              :disabled="isLoadingRequest"
              v-if="interview.status.id === 'client_pending_update' || interview.status.id === 'client_pending' && interview.status.id !== 'talent_pending'"
              @click="save"
              class="tw-px-4 tw-py-3 md:tw-py-3.5 tw-w-full tw-bg-[#ffc629] md:tw-w-auto  md:tw-min-w-[10rem] tw-rounded-lg tw-border-[#d7d7d7] tw-justify-center tw-items-center tw-gap-2.5 tw-flex text-[#292929] tw-text-[15px]  md:tw-text-base tw-font-bold">
              
               {{ $t('requestInterview.submit')}}
            </button>
          </div>

    </div>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import VulidateError from "@/components/shared/VulidateError/vulidateError.vue";
import { DatePicker, Calendar } from "v-calendar";
import moment from "moment";
import momentTimezone from "moment-timezone";

export default {
  components: {
    DatePicker,
    Calendar,
    VulidateError,
  },
  props: {
    fullTimeAvailability: {
      type: Boolean,
    },
    partTimeAvailability: {
      type: Boolean,
    },
    interview: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      isLoadingRequest: false,
      selectedDate: null,
      days: [],
      time: new Date(),
      error: false,
      disabledDates: [
        { weekdays: [6, 7] }, // Disable weekends (Saturday and Sunday)
        {
          start: new Date('2025-03-30'),
          end: new Date('2025-04-07')
        }
      ],
      selectedTimezone: "Africa/Cairo",
      timezones: [],
      engagementInfo: {
        availability: null,
        duration: null,
      },
      durationsList: [
        {
          id: 6,
          name: this.$t("clientWizard.sixMonths"),
        },
        {
          id: 12,
          name: this.$t("clientWizard.twelveMonths"),
        },
        {
          id: 24,
          name: this.$t("clientWizard.twentyFourMonths"),
        },
        {
          id: 36,
          name: this.$t("clientWizard.thirtySixMonths"),
        },
      ],
    };
  },
  validations: {
    engagementInfo: {
      availability: { required },
      duration: { required },
    },
    days: {
      $each: {
        time: {
          required,
        },
      },
    },
  },
  computed: {
    getValidHours() {
      return this.days.map((date) => {
        const validHoursUTC = [7, 8, 9, 10, 11, 12, 13, 14, 15];
        const validHoursLocal = validHoursUTC.map((hour) => {
          const localHour = momentTimezone
            .tz(new Date(Date.UTC(1970, 0, 1, hour)), this.selectedTimezone)
            .hour();
          return localHour;
        });

        const today = momentTimezone().tz(this.selectedTimezone).toDate();
        const selectedDate = momentTimezone(date)
          .tz(this.selectedTimezone)
          .toDate();
        if (today.toDateString() === selectedDate.toDateString()) {
          const currentHour = today.getHours();
          return validHoursLocal.filter((hour) => hour > currentHour);
        }
        return validHoursLocal;
      });
    },
    dates() {
      return this.days.map((day) => day.date);
    },
    attributes() {
      return this.dates.map((date) => ({
        highlight: true,
        key: date.toDateString(),
        dates: date,
      }));
    },
    availabilitiesList() {
      return [
        {
          id: 2,
          name: this.$t("clientWizard.fullTime"),
          disabled: !this.fullTimeAvailability,
        },
        {
          id: 1,
          name: this.$t("clientWizard.partTime"),
          disabled: !this.partTimeAvailability,
        },
      ]
    }
  },
  mounted() {
    const bothAvailable = this.fullTimeAvailability && this.partTimeAvailability;
    const noneAvailable = !this.fullTimeAvailability && !this.partTimeAvailability;

    this.engagementInfo.availability = bothAvailable || noneAvailable
      ? null
      : this.fullTimeAvailability ? 2 : 1;

    this.checkTodayAvailability();
    this.loadTimezones();
  },
  methods: {
    loadTimezones() {
      this.selectedTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      let timezones = momentTimezone.tz.names();
      let uniceTimezones = new Set(timezones)

      timezones = [...uniceTimezones]


      timezones = timezones.map((timezone) => {
        const gmtOffset = momentTimezone()
          .tz(timezone)
          .format("Z")
          .replace(":00", "");
        return {
          name: `${timezone.split("/")[1]} ${timezone.split("/")[2] ? '/' + timezone.split("/")[2].replace("_", " ") : ''}  (GMT${gmtOffset})`,
          value: timezone,
        };
      }).filter((timezone) => !timezone.name.includes('undefined'));
      this.timezones = timezones;
    },
    getValidHoursInToday(date) {
      const validHoursUTC = [7, 8, 9, 10, 11, 12, 13, 14, 15];
      const validHoursLocal = validHoursUTC.map((hour) => {
        const localHour = momentTimezone
          .tz(new Date(Date.UTC(1970, 0, 1, hour)), this.selectedTimezone)
          .hour();
        return localHour;
      });

      const today = momentTimezone().tz(this.selectedTimezone).toDate();
      const selectedDate = new Date(date);
      if (today.toDateString() === selectedDate.toDateString()) {
        const currentHour = today.getHours();
        return validHoursLocal.filter((hour) => hour > currentHour);
      }
      return validHoursLocal;
    },
    checkTodayAvailability() {

      const today = new Date();
      const availableSlots = this.getValidHoursInToday(today);
      
      // If no available slots, disable today
      if (availableSlots.length === 0) {
        this.disabledDates.dates.push(today); // Add today's date to the disabled dates
      }
    },
    dataFormat(date) {
      if (this.$i18n.locale == "en") {
        moment.locale("en");
        return moment(date).format("D MMM");
      } else {
        moment.locale("ar");
        return moment(date).format("D MMM");
      }
    },
    save() {
      if(this.isLoadingRequest) return;
      this.isLoadingRequest = true;
      this.error = false;
      this.$v.engagementInfo.$touch();
      if (this.days.length !== 2) {
        this.error = true;
        this.isLoadingRequest = false;
        return;
      }
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.isLoadingRequest = false;
        return;
      }
      moment.locale("en");
      const payload = {
        status: "talent_pending",
        required_resource: {
          contract_type: this.engagementInfo.availability,
          duration: this.engagementInfo.duration,
        },
        slots:
          this.days.map((day) => {
            const date = moment(day.date).format("YYYY-MM-DD");
            const time = moment(day.time).format('HH:mm');
            return `${date} ${time}`;
          }),
        timezone: this.selectedTimezone == 'Etc/UTC' ? 'UTC' : this.selectedTimezone
      };

      this.axios
        .put(`/api/talent-selections/${this.$route.params.id}`, payload)
        .then(() => {
          this.$emit("engagementInfoUpdated",);
          this.isLoadingRequest = false;
        })
        .catch((error) => {
          this.isLoadingRequest = false;
          console.log(error);
        });
    },
    onDayClick(day) {
      if (day.isDisabled) return;

      const idx = this.days.findIndex((d) => d.id === day.id);
      if (idx >= 0) {
        this.days.splice(idx, 1);
        this.$nextTick(() => {
          document.body.click();
        });
      } else {
        if (this.days.length < 2) {
          this.days.push({
            id: day.id,
            date: day.date,
            time: null,
          });
        } else {
          this.error = true;
        }
      }
    },
    handleChangeAvailability(engagementInfoId, resetEngagementDuration) {
      if(resetEngagementDuration) {
        this.engagementInfo.duration = null;
      }
      const baseDurations = [
        { id: 6, name: this.$t("clientWizard.sixMonths") },
        { id: 12, name: this.$t("clientWizard.twelveMonths") },
        { id: 24, name: this.$t("clientWizard.twentyFourMonths") },
        { id: 36, name: this.$t("clientWizard.thirtySixMonths") },
      ];

      this.durationsList = engagementInfoId === 1
        ? [{ id: 3, name: this.$t("clientWizard.threeMonths") }, ...baseDurations]
        : baseDurations;
    }
  },
  watch: {
    selectedTimezone: {
      immediate: true,
      handler() {
        this.days = [];
        this.checkTodayAvailability();
      }
    },
    "$i18n.locale"() {
      this.handleChangeAvailability(this.engagementInfo.availability, false)
      this.$emit("updated");
    },
  },
};
</script>

<style lang="scss" scoped></style>


<style lang="scss">
.engagement-info {
  .ltr {
    direction: ltr;
  }

  .vs__selected-options {

    height: 50px;

    @media (max-width: 576px) {
      height: 2.9rem;
    }

  }

  .vs__selected {
    font-size: 14px;
  }

  .vc-container {
    box-shadow: #0000004a 0px 0px 0px 0px !important;
    border: 1px solid #f5f5f5;
  }

  .vc-weeks {
    height: 22rem;
    width: 350px;
    margin: auto;

    @media (max-width: 576px) {
      width: 100%;
      height: auto;
      
    }
  }

  .vc-arrows-container {
    width: 55%;
    left: 50%;
    transform: translate(-50%, 0);

    @media (max-width: 576px) {
      width: 100%;
    }
  }

  .vc-arrow {
    color: #ffc629;
    font-size: 1.5rem;
  }

  .vc-title {
    color: #161616;
    font-size: 22px;
    font-weight: 700;
  }

  .vc-highlight {
    background-color: #ffc629 !important;
    color: #fff !important;
    border-radius: 14px !important;
    width: 2.2rem !important;
    height: 2.2rem !important;
  }

  .vc-day-content:hover {
    background-color: #ffc629 !important;

    color: #fff !important;
    border-radius: 14px !important;
    width: 2.2rem !important;
    height: 2.2rem !important;
  }

  .vc-day-content:focus:not(:hover):not(.vc-highlight) {
    background-color: #ffc6291c !important;
    color: #000 !important;
    border-radius: 14px !important;
    width: 2.2rem !important;
    height: 2.2rem !important;
  }

  .time-slot {
    .vc-date {
      display: none;
    }

    .vc-time-icon {
      display: none;
    }

    .vc-container {
      border: none;
      height: 2.125rem;
      display: flex;
      align-items: center;
    }

    .vc-select select {
      background-color: #fff;
      border: none;
      width: auto;
      padding: 0 8px 0 8px;
    }

    .vc-select-arrow {
      display: none;
    }

    .vc-am-pm button.active {
      background-color: #ffc629;
      color: #fff;
    }

    .vc-am-pm button.active:focus {
      border-color: #ffc629;
    }

    .vc-am-pm button.active:hover {
      background: #ffc629;
    }
  }
}
</style>
