<template>
  <div class="provideFeedback">
    <div class="provideFeedback__content">
      <img src="@/assets/images/undraw_feedback_re_urmj-2 1.svg" alt="client feedback poster">
      <p class="tw-text-center tw-text-xl tw-font-bold tw-mt-8 tw-text-black">
        You’ve had a meeting with talent at
      </p>
      <time class="tw-text-center">
        {{ meetingTime }}
      </time>
      <span class="tw-text-center tw-mt-3 tw-text-[#422E87]">
        waiting for your feedback on talent
      </span>
      <div class="w-full tw-mt-3 tw-flex tw-flex-col-reverse md:tw-flex-row md:tw-items-center md:tw-justify-center tw-gap-4">
        <router-link
          :to="`/sq/client/feedback-form?selection=${selectionId}&action=rejected`"
          class="tw-block md:tw-inline-block tw-text-center md:tw-inline-block tw-bg-white tw-border tw-text-[#FF6F59] tw-py-2 tw-px-8 tw-rounded-lg"
        >
          Reject Talent
        </router-link>
        <router-link
          :to="`/sq/client/feedback-form?selection=${selectionId}&action=accepted`"
          class="tw-block md:tw-inline-block tw-text-center md:tw-inline-block tw-bg-white tw-border tw-border-[#3BD4AE] tw-text-[#3BD4AE] tw-py-2 tw-px-8 tw-rounded-lg"
        >
          Accept Talent
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "provideFeedbackCta",
  props: {
    meetingTime: {
      type: String,
      required: true,
    },
    selectionId: {
      type: String,
      required: true,
    },
  }
};
</script>

<style lang="scss">
.provideFeedback {
  background-color: #fff;
  border-radius: 20px;
  padding: 89px 53.5px;

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
</style>
