<template>
  <div class="interview-details">
    <div class="container">
      <div class="interview-details__wrapper" v-if="loading">

        <div class="tw-max-w-full tw-p-4 tw-space-y-2 md:tw-space-y-6 tw-animate-pulse">
          <div class="tw-space-y-2">
            <div class="tw-w-3/4 tw-h-4 tw-bg-gray-300 tw-rounded"></div>
            <div class="tw-w-1/2 tw-h-4 tw-bg-gray-300 tw-rounded"></div>
          </div>
          <div class="tw-w-full tw-h-8 tw-bg-gray-300 tw-rounded-md"></div>

          <div class="tw-w-full tw-h-48 tw-bg-gray-300 tw-rounded-lg"></div>
        </div>
      </div>
      <div class="interview-details__wrapper" v-if="!loading">
        <div class="tw-max-w-5xl tw-mx-auto tw-flex md:tw-justify-end tw-mb-4 tw-mt-6">
          <span class="tw-inline-block tw-w-28 tw-text-xs tw-whitespace-nowrap tw-text-center tw-px-4 tw-py-1 tw-rounded-full tw-border tw-text-[#422E87] tw-border-[#422E87] tw-select-none">
            {{ $t('matchedTalents.beta') }}
          </span>
        </div>
        <div class="tw-flex tw-flex-col tw-space-y-2 md:tw-space-y-6 md:tw-flex-row md:tw-justify-between">
          <div class="tw-flex tw-flex-col-reverse tw-gap-2 md:tw-flex-col-reverse">
            <div v-if="interview.status.id === 'talent_unavailable'"
              class="tw-bg-[#ff4a551a] tw-px-4 tw-py-2 tw-rounded-3xl tw-text-[#ff4a55] tw-text-sm tw-font-medium tw-leading-normal tw-w-max">
              {{$t('requestInterview.talentUnavailable')}}
            </div>
             <div v-if="interview.status.id === 'canceled'"
              class="tw-bg-[#ff4a551a] tw-px-4 tw-py-2 tw-rounded-3xl tw-text-[#ff4a55] tw-text-sm tw-font-medium tw-leading-normal tw-w-max">
               {{$t('requestInterview.canceled')}}
            </div>
            <div v-if=" interview.status.id === 'client_pending'"
              class="tw-bg-[#f876141a] tw-px-4 tw-py-2 tw-rounded-3xl tw-text-[#f87614] tw-text-sm tw-font-medium tw-leading-normal tw-w-max ">
                {{$t('requestInterview.pendingClientConfirmationOnNewSlots')}}
            </div>
            <div v-if="interview.status.id === 'client_pending_update'"
              class="tw-bg-[#f876141a] tw-px-4 tw-py-2 tw-rounded-3xl tw-text-[#f87614] tw-text-sm tw-font-medium tw-leading-normal tw-w-max ">
                {{$t('requestInterview.pendingClientTimeSlots')}}
            </div>
            <div v-if="interview.status.id === 'scheduled'"
              class="tw-bg-[#3bd4ae1a] tw-px-4 tw-py-2 tw-rounded-3xl tw-text-[#3BD4AE] tw-text-sm tw-font-medium tw-leading-normal tw-w-max ">
               {{$t('requestInterview.interviewScheduled')}}
            </div>
             <div v-if="interview.status.id === 'completed'"
              class="tw-bg-[#3bd4ae1a] tw-px-4 tw-py-2 tw-rounded-3xl tw-text-[#3BD4AE] tw-text-sm tw-font-medium tw-leading-normal tw-w-max ">
               {{$t('requestInterview.interviewCompleted')}}
            </div>
            <div v-if="interview.status.id === 'talent_pending'"
              class="tw-bg-[#1f39d21a] tw-px-4 tw-py-2 tw-rounded-3xl tw-text-[#1F39D2] tw-text-sm tw-font-medium tw-leading-normal tw-w-max ">
              {{$t('requestInterview.pendingTalentMeetingConfirmation')}}
            </div>
             <div v-if="interview.status.id === 'talent_pending_update'"
              class="tw-bg-[#1f39d21a] tw-px-4 tw-py-2 tw-rounded-3xl tw-text-[#1F39D2] tw-text-sm tw-font-medium tw-leading-normal tw-w-max ">
               {{$t('requestInterview.pendingTalentConfirmation')}}
            </div>
            

          
            <h1 class="tw-w-full tw-text-[#1b1b1b] tw-text-lg tw-font-medium md:tw-text-3xl">
              {{ $t('requestInterview.title')}}
            </h1>
            
          </div>
          <div class="tw-hidden tw-items-center tw-gap-4 tw-my-auto">
            <button
              v-if="interview.status.id === 'client_pending_update' || interview.status.id === 'client_pending' || interview.status.id === 'talent_pending' || interview.status.id === 'talent_pending_update'"
              @click="isCancelModalOpen = true"
              class="tw-px-4 tw-py-3 md:tw-py-3.5 tw-w-full md:tw-min-w-[10rem] tw-rounded-lg tw-border-[#d7d7d7] tw-justify-center tw-items-center tw-border tw-gap-2.5 tw-flex tw-text-center tw-text-[#ff6f59] tw-text-[15px]  md:tw-text-base tw-font-medium">
              {{ $t('requestInterview.cancelProcess')}}
            </button>
            <button
              v-if="interview.status.id === 'client_pending_update' || interview.status.id === 'client_pending' && interview.status.id !== 'talent_pending'"
              @click="submit"
              class="tw-px-4 tw-py-3 md:tw-py-3.5 tw-w-full tw-bg-[#ffc629] md:tw-min-w-[10rem] tw-rounded-lg tw-border-[#d7d7d7] tw-justify-center tw-items-center tw-gap-2.5 tw-flex text-[#292929] tw-text-[15px]  md:tw-text-base tw-font-bold">
              
               {{ $t('requestInterview.submit')}}
            </button>
          </div>

       
        </div>

        <div class="tw-mt-6">
          <ProfileUnavailable v-if="interview.status.id === 'talent_unavailable'" :talent="interview.talent" />

          <TalentDetails
            v-if="interview.status.id !== 'talent_unavailable'"
            :status="interview.status.id"
            :talent="interview.talent"
            :fullTimeStatus="interview.full_time_status ? interview.full_time_status.id : null"
            :fullTimeSalary="interview.full_time_salary ? interview.full_time_salary.total_salary : null"
            :partTimeStatus="interview.part_time_status ? interview.part_time_status.id : null"
            :partTimeSalary="interview.part_time_salary ? interview.part_time_salary.total_salary : null"
            :originalFullTimeSalary="interview.full_time_salary ? interview.original_full_time_salary.total_salary : null"
            :originalPartTimeSalary="interview.part_time_salary ? interview.original_part_time_salary.total_salary : null"
          />

          <WaitingTalent @cancel="isCancelModalOpen = true" :interview="interview" v-if="interview.status.id === 'talent_pending_update'" />

          <EngagementInfo
            v-if="interview.status.id === 'talent_pending' || interview.status.id === 'scheduled' || interview.status.id === 'client_pending' || interview.status.id === 'completed'"
            :interview="interview"
          />
          
          <ProvideFeedbackCta
            v-if="interview.status.id === 'completed'"
            :selection-id="interview.id"
            :meeting-time="
              meetingTime(
                interview.interview.meeting_start_time,
                interview.interview.timezone
              )
            "
          />

          <InterviewCyclePickSlots
            v-if="interview.status.id === 'client_pending_update'"
            @engagementInfoUpdated="getInterviewDetails(false)"
            :fullTimeAvailability="interview.full_time_status?.id > 0"
            :partTimeAvailability="interview.part_time_status?.id > 0"
            :interview="interview"
            @cancel="isCancelModalOpen = true"
            @updated="getInterviewDetails(false)"
            ref="InterviewCyclePickSlotsRef"
          />
          
          <InterviewCycleSubmitted  @cancel="isCancelModalOpen = true" :interview="interview" v-if="interview.status.id === 'talent_pending'"/>
          
          <InterviewScheduled @fetchInterview="getInterviewDetails(false)" :interview="interview" v-if="interview.status.id === 'scheduled'"/>

          <InterviewCycleProposedSlots  @cancel="isCancelModalOpen = true" @updated="getInterviewDetails(false)" ref="InterviewCycleProposedSlotsRef" :interview="interview" v-if="interview.status.id === 'client_pending'"/>
          
        </div>
      </div>
    </div>

    <CancelsInterviewModel v-if="isCancelModalOpen" :isOpen="isCancelModalOpen" @update="isCancelModalOpen = $event" @cancelled="cancelled" />

    <CancelConfirmationModel v-if="isCancelConfirmationModelOpen" :isOpen="isCancelConfirmationModelOpen"  @update="isCancelConfirmationModelOpen = $event" />

  </div>
</template>

<script>
import moment from "moment";
import momentTimezone from "moment-timezone";
import ProfileUnavailable from '@/components/InterviewsDetails/ProfileUnavailable.vue';
import WaitingTalent from '@/components/InterviewsDetails/WaitingTalent.vue';
import CancelsInterviewModel from "@/components/InterviewsDetails/CancelsInterviewModel.vue"
import TalentDetails from "@/components/InterviewsDetails/TalentDetails.vue"
import InterviewCyclePickSlots from "@/components/InterviewsDetails/InterviewCyclePickSlots.vue"
import InterviewCycleSubmitted from "@/components/InterviewsDetails/InterviewCycleSubmitted.vue"
import InterviewScheduled from "@/components/InterviewsDetails/InterviewScheduled.vue"
import InterviewCycleProposedSlots from "@/components/InterviewsDetails/InterviewCycleProposedSlots.vue"
import EngagementInfo from "@/components/InterviewsDetails/EngagementInfo.vue"
import ProvideFeedbackCta from "@/components/InterviewsDetails/provideFeedbackCta.vue"
import CancelConfirmationModel from '@/components/InterviewsDetails/CancelConfirmationModel.vue'

export default {
  name: "interviewsDetails",
  components: {
    ProfileUnavailable,
    CancelsInterviewModel,
    WaitingTalent,
    TalentDetails,
    InterviewCyclePickSlots,
    InterviewCycleSubmitted,
    InterviewScheduled,
    InterviewCycleProposedSlots,
    EngagementInfo,
    ProvideFeedbackCta,
    CancelConfirmationModel
  },
  data() {
    return {
      interview: {},
      loading: true,
      isCancelModalOpen: false,
      isCancelConfirmationModelOpen: false,
    }
  },
  computed: {
    timezones() {
      let timezones = momentTimezone.tz.names();
      timezones = timezones.map((timezone) => {
        return {
          name: `${timezone.split("/")[1]} Time`,
          value: timezone,
        };
      });
      return timezones;
    },
  },
  mounted() {
    this.getInterviewDetails(true);
  },
  methods: {
    submit() { 
      if (this.interview.status.id === 'client_pending') {
        this.$refs.InterviewCycleProposedSlotsRef.confirm();
      }else {
         this.$refs.InterviewCyclePickSlotsRef.save();
      }
    },
    cancelled() {
      this.isCancelConfirmationModelOpen = true;
      this.getInterviewDetails(false)
    },
    meetingTime(time, timezone) {
      if (this.$i18n.locale == "en") {
        moment.locale("en");
        const date = moment(time).format("D MMMM YYYY");
        const timeStr = moment(time).format("h:mm A");
        const offset = moment.tz(timezone).format('Z');
        return `${date} at ${timeStr} ${this.timezones.find((tz) => tz.value == timezone)?.name} (GMT${offset})`;
      } else {
        moment.locale("ar");
        const offset = moment.tz(timezone).format('Z');
        return ` ${
          this.timezones.find((tz) => tz.value == timezone)?.name
        } (GMT${offset}) ${moment(time).format("YYYY/MM/DD")} فى ${moment(time).format(
          "h:mm A"
        )}`;
      }
    },
    getInterviewDetails(loading) {
      // API call to get interview details
      if (loading) this.loading = true;

      this.axios.get(`/api/talent-selection/${this.$route.params.id}`).then((response) => {
      this.interview = response.data.data;
        
      }).catch((error) => {
        console.log(error);
      }).finally(() => {
        this.loading = false;
      });
    }
  },

};
</script>

<style lang="scss">
.interview-details {
  &__wrapper {
    position: relative;
    z-index: 0;
    padding: 0 20px;
    border-radius: 18px;
    margin: 10px auto;

    @media (min-width: 1200px) {
      width: 84%;
    }
  }
}
</style>